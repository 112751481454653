<template>
    <div>
        ...logging out.
    </div>
</template>

<script>
    export default {
        name: 'Logout',
        computed: {

        },
        mounted() {
            //console.log('logging out page');
            this.$BlitzIt.auth.logout();            
        },      
    }
</script>